.header-logo{
  max-height: 8vh;
}
.group-name{
  font-weight: bold;
  font-size: 27px;
  color: #03144A;
}
.icon-up, .icon-down{
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.player-name{
  font-size: 23px;
  margin: 0;
  color: #696871;
}
.helper-text{
  color: #017D9B;
  font-style: italic;
  margin: 0; 
  margin-bottom: 10px;
  font-size: 20px;
}

.icon-winner{
  height: 30px;
}
.right{
  color: #019b5b;
}
.wrong{
  color: #bd1919
}