.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 40px;
  border-radius: 25px;
}

.login-logo {
  max-height: 15vh;
}

.login-logo-name {
  max-width: 20vw;
}